<template>
    <section
        ref="observerRef"
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Newsletter</div>
                </div>
            </div>
        </div>

        <div class="c-newsletter" v-if="inView">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-middle">
                    <div class="small-12 medium-12 large-2 cell">
                        <h2
                            v-if="componentData.settings.heading_level == 'h2'"
                            class="c-newsletter__title wow animate__animated animate__fadeIn"
                            v-html="componentData.title"></h2>

                        <h3
                            v-else-if="componentData.settings.heading_level == 'h3'"
                            class="c-newsletter__title wow animate__animated animate__fadeIn"
                            v-html="componentData.title"></h3>

                        <div
                            v-else
                            class="c-newsletter__title wow animate__animated animate__fadeIn"
                            v-html="componentData.title"></div>
                    </div>

                    <div class="small-12 medium-12 large-9 large-offset-1 cell">
                        <div class="c-contact-form__intro is-mobile">
                            Fields marked with <sup style="color: red">*</sup> are required.
                        </div>

                        <ClientOnly>
                            <PartialsContactForm
                                is_newsletter="true"
                                :form_id="componentData.form"
                                :redirect_url="componentData.redirect_url" />
                        </ClientOnly>

                        <div class="c-contact-form__intro is-desktop">
                            Fields marked with <sup style="color: red">*</sup> are required.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });
    const observerRef = ref(null)
    const inView = ref(false)

    useLazyObserver(observerRef, () => { 
        inView.value = true
    })

    // console.log("Component Data: ", props.componentData);
</script>
